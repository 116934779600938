import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TimeAgo from 'react-timeago';
import Button from '@material-ui/core/Button';
import FollowIcon from '@material-ui/icons/Favorite';
import UnfollowIcon from '@material-ui/icons/FavoriteBorder';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';

import RefreshCharacterButtonContainer from '../../containers/RefreshCharacterButton';
import PotentialAlts from '../PotentialAlts/';
import FactionIcon from '../FactionIcon/';
import TwitchIcon from '../../assets/integrations/twitch.svg';
import {
  getCharacterInfo,
  getSpecIcon,
  getClassColor,
  getFactionColor,
  getCovenantIcon,
} from '../../utils/helpers';
import Achievements from '../Achievements/';
import LegionAchievements from '../LegionAchievements/';
import ItemLevelIcon from '../../assets/item-level.svg';
import AchievementIcon from '../../assets/achievement.svg';
import './CharacterInfo.scss';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 14,
  },
});

const CharacterInfo = ({ character, currentAccount, handleFollow }) => {
  const classColor = getClassColor(character.character_class);
  const factionColor = getFactionColor(character.faction);
  const avatarUrl =
    character.avatar_url || getSpecIcon(character.character_class, character.specialization);
  const classes = useStyles();

  return (
    <div id="character-info-container" style={{ border: '3px solid #1f2527' }}>
      <div className="header">
        <img
          className="character-avatar"
          src={avatarUrl}
          alt="Character Avatar"
          style={{ borderColor: classColor }}
        />

        <div className="character-info">
          <a href={character.armory_url} target="_blank" rel="noopener noreferrer">
            <h1 style={{ color: classColor }}>
              {character.name} - {character.realm_name}
            </h1>
          </a>

          <span
            className="character-spec badge"
            style={{ backgroundColor: classColor, color: '#000' }}
          >
            {getCharacterInfo(character)}
          </span>

          <span className="guild" style={{ color: factionColor }}>
            {character.guild_name && `<${character.guild_name}>`}
          </span>
        </div>

        <div className="faction">
          <FactionIcon faction={character.faction} />

          <div className="refresh">
            <RefreshCharacterButtonContainer className="btn" />
            <TimeAgo
              date={character.updated_at}
              formatter={(value, unit, suffix) => {
                if (unit === 'second') return 'Just now';
                const plural = value !== 1 ? 's' : '';
                return `${value} ${unit}${plural} ${suffix}`;
              }}
            />
          </div>
        </div>
      </div>

      <div className="achievements">
        {get(character, 'covenant', null) && get(character, 'renown_level', null) > 0 && (
          <div className="covenant">
            <img
              src={getCovenantIcon(character.covenant)}
              alt="Covenant"
              style={{ width: 40, marginRight: 5 }}
            />
            {character.covenant} - Rank {character.renown_level}
          </div>
        )}

        <div className={`stat-boxes ${currentAccount.isSignedIn ? 'auth' : ''}`}>
          <div className="box">
            <ItemLevelIcon width={28} height={28} fill="#c43244" />
            <span>{character.item_level} ilvl</span>
          </div>

          <Tooltip title="Achievement Points" classes={{ tooltip: classes.tooltip }}>
            <div className="box">
              <AchievementIcon width={28} height={28} fill="#c43244" />

              <span>{character.achievement_points}</span>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className={character.covenant ? 'buttons' : 'buttons goTop'}>
        {currentAccount.isSignedIn && (
          <Button
            variant="contained"
            className="btn follow-btn primary-btn"
            onClick={() => handleFollow(character)}
          >
            {character.is_following ? <FollowIcon /> : <UnfollowIcon />}
            <span>{character.is_following ? 'Following' : 'Follow'}</span>
          </Button>
        )}

        {character.twitch_url && (
          <a
            href={character.twitch_url}
            className="twitch-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" className="btn twitch-btn">
              <TwitchIcon style={{ width: 40 }} />
              <span>Twitch Stream</span>
            </Button>
          </a>
        )}
      </div>

      <Achievements
        title="Account Achievements"
        achievements={character.significant_achievements}
        achievementMappings={character.achievement_mappings}
      />

      <Achievements
        title="R1 Achievements"
        achievements={character.rank_one_achievements}
        achievementMappings={character.achievement_mappings}
      />

      <Achievements
        title="Dragonflight Achievements"
        achievements={character.dragonflight_achievements}
        achievementMappings={character.achievement_mappings}
      />

      <Achievements
        title="Shadowlands Achievements"
        achievements={character.shadowlands_achievements}
        achievementMappings={character.achievement_mappings}
      />

      <Achievements
        title="BFA Achievements"
        achievements={character.bfa_achievements}
        achievementMappings={character.achievement_mappings}
      />

      <LegionAchievements
        achievements={character.legion_2k_achievements}
        achievementMappings={character.achievement_mappings}
      />

      {!character.alt_hidden && (
        <PotentialAlts
          alts={character.alts.filter(alt => !alt.alt_hidden)}
          title={character.has_confirmed_alts ? 'Alts' : 'Potential Alts'}
        />
      )}
    </div>
  );
};

CharacterInfo.propTypes = {
  character: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  handleFollow: PropTypes.func.isRequired,
};

export default CharacterInfo;
